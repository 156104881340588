import { get, post } from '@/utils/request';


const removeUser=data=>{
  return post({
    url:'/store/authority.user/deleteUser',
    data,
  })
}

const ListRequest = (params) => {
  return post({
    url: '/store/authority.user/list',
    data: {
      page: 1,
      limit: 999,
      ...params,
    },
  });
};

const edit = ({ id, realname, username, gender, hiredate, status, hierarchy_group }) => {
  return post({
    url: '/store/authority.user/edit',
    data: {
      id,
      realname,
      gender,
      hiredate,
      username,
      status,
      hierarchy_group,
    },
  });
};


// 添加员工
const create = ({ realname, username, gender, hiredate, status, hierarchy_group }) => {
  return post({
    url: '/store/authority.user/create',
    data: {
      realname,
      gender,
      hiredate,
      username,
      status,
      hierarchy_group,
    },
  });
};

const userDelete = (admin_user_id) => {
  return get({
    url: '/admin/user.user/delete',
    params: {
      admin_user_id,
    },
  })
}

const hierListReq = () => {
  return get({
    url: '/admin/user.user/hierList'
  })
}

const roleListReq = (hierarchy_id) => {
  return post({
    url: '/store/authority.group/list',
    data: {
      hierarchy_id,
    },
  });
};

// 选择门店
const getMerchantListReq = () => post({
  url: '/store/common.select/getMerchantList',
});

// 选择门店，去除商家数据
const getMerchants = (data) => post({
  url: '/store/common.select/getMerchants',
  data: data,
})
// 获取绑定URL
const bindEwechatUrl = (data) => post({
  url: '/store/QiyeApp.EwechatAuthConfigCtl/bindEwechatUrl',
  data: data,
})
// 解绑企业微信
const unbindEwechat = (data) => post({
  url: '/store/QiyeApp.EwechatAuthConfigCtl/unbindEwechat',
  data: data,
})

// 获取绑定企微个人信息接口
const bindPersonEwechatUrl = (data) => post({
  url: '/store/QiyeApp.EwechatAuthConfigCtl/bindPersonEwechatUrl',
  data: data,
})

export {
  removeUser,
  ListRequest,
  edit,
  create,
  userDelete,
  hierListReq,
  roleListReq,
  getMerchantListReq,
  getMerchants,
  bindEwechatUrl,
  unbindEwechat,
  bindPersonEwechatUrl
};
