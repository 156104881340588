<template>
  <div class="content">
    <div class="wrapper">
      <div class="left">
        <div class="left-logo">
          <!-- <img src="../assets/images/basicLayout/logo.png" /> -->
          <img
            src="http://etouch-erp-test.oss-cn-shenzhen.aliyuncs.com/logo.png"
          />
        </div>
        <div class="left-list">
          <template v-for="(item, index) in routes">
            <div
              class="left-list-item"
              :key="item.key"
              @click="handleRoutes(index, item, 'changeCurrent')"
              :class="item.active ? 'item-active1' : ''"
            >
              <div class="left-list-item-icon" v-if="item.icon">
                <!-- <img :src="require('../assets/images/basicLayout/'+ item.icon +'.png')" /> -->
                <i
                  class="iconfont"
                  :class="item.icon"
                  style="font-size: 22px; color: white"
                ></i>
              </div>
              <div class="left-list-item-title">{{ item.title }}</div>
            </div>
          </template>
        </div>
        <div class="left-txt">@TOBGO</div>
      </div>
      <div class="drawer">
        <div class="drawer-list" :class="show ? 'slide' : ''">
          <template
            v-if="(currIndex || currIndex === 0) && routes[currIndex].children"
          >
            <div
              class="drawer-list-item"
              v-for="(item, index) in routes[currIndex].children"
              :key="item.key"
              @click="handleRoutes(index, item)"
            >
              <div
                class="drawer-list-item-header"
                :class="item.active ? 'item-active2' : ''"
              >
                {{ item.title }}
              </div>
              <div class="box">
                <template v-if="item.children">
                  <div
                    class="drawer-list-item-txt"
                    v-for="(child, childIndex) in item.children"
                    :key="child.key"
                    :class="child.active ? 'item-active3' : ''"
                    @click.stop="handleRoutes(childIndex, child,'',1)"
                  >
                    {{ child.title }}
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
        <div
          class="drawer-mask"
          @click="show = false"
          :class="show ? 'drawer-mask-show' : ''"
        ></div>
      </div>
      <div class="right" >
        <div class="right-header">
          <div
            class="right-header-tabs"
          >
            <el-tabs
              v-model="currTab"
              type="card"
              @tab-remove="handleClose"
              @tab-click="handleClick"
            >
              <el-tab-pane

                v-for="item in tabs"
                :key="item.key"
                :label="item.title"
                :name="item.key"
                :closable="item.key !== '0' ? true : false"
              >
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="right-header-msg" ref="right">
            <div class="user">
              <div class="user-avatar">
                <el-image
                  style="
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    overflow: hidden;
                  "
                  :src="userInfo.avatar"
                  fit="cover"
                  :preview-src-list="[userInfo.avatar]"
                >
                  <div slot="error" class="image-slot">
                    <img src="../assets/images/basicLayout/avatar.jpg" />
                  </div>
                </el-image>
              </div>
              <div>
                <div style="display: flex; align-items: center; height: 33px">
                  <div class="user-name">{{ userInfo.realname }}</div>
                  <div class="user-desc">{{ userInfo.role }}</div>
                  <div @click="changeShop" class="change" v-if="canChange">
                    <i class="iconfont iconqiehuanmendian"></i>
                  </div>
                </div>
                <div
                  style="
                    font-size: 12px;
                    padding-left: 10px;
                    color: #90949a;
                    margin-top: -5px;
                  "
                >
                  {{ userInfo.hierarchy_name }}
                </div>
              </div>
            </div>
            <div class="options">
              <div class="options-item1" style="margin-right: 20px;" @click="toSearch">
                <i class="iconfont iconsousuo" style="font-size: 22px; color: #909399;"></i>
              </div>
              <!-- <div class="options-item2">
                <i class="iconfont iconxiaoxi" style="font-size: 24px; color: #909399;"></i>
                <div class="point">2</div>
              </div> -->
              <div class="options-item3" @click="handleLayout">
                <!-- <img src="../assets/images/basicLayout/icon_drop out.png" /> -->
                <i
                  class="iconfont icontuichu"
                  style="font-size: 22px; color: #909399"
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div class="right-content">
          <div class="right-content-wrapper">

            <keep-alive>
              <router-view :key="$route.query.t" />
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable */
import routes from "@/router/routes";
import Cookies from "js-cookie";
import storage from "good-storage";
import { myMenuReq, getShopListReq } from "@/api/basicLayout";
import { mapState, mapMutations } from "vuex";

export default {
  name: "BasicLayout",
  data() {
    return {
      timer: null,
      hierarchy_id: '', // 当前门店名称
      canChange: false, // 是否能切换门店
      num: 0,
      onLine: navigator.onLine,
      rightWidth: 0, // 头部右边的宽度
      same: false,
      srcList: [],
      currRoute: {}, // 当前路由信息
      show: false,
      currIndex: null,
      imgPath: require("../assets/images/basicLayout/icon_home.png"),
      width: null,
      tabs: [
        {
          key: "0",
          title: "首页",
          path: "/index",
          query: {},
        },
      ], // tabs列表，首页为固定
      currTab: null,
      routes: [], // 路由列表
      userInfo: {},
    };
  },

  methods: {//页面内关闭标签页，目前只支持详情页面关闭
    closetab(){
      this.handleClose(this.$route.path)
    },

    //页面切换标签
    previousPageTab(path){
      if(this.$route.matched.length>0){
        // 选择标签
        this.currTab=path;
        this.tabs.forEach((item) => {
            if (item.path === this.currTab) {

              this.$router.replace({
                path: item.path,
                query: item.query,
                t: Date.now(),
              });
            }
        });
      }
    },
    toSearch() {
      this.$router.push({
        path: '/goods/goodsSearch/list',
      })
    },
    // 切换门店
    changeShop() {
      this.num = 0;
      this.$router.replace({
        name: "NewLogin",
        params: {
          chooseShop: true,
        },
      });
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
    ...mapMutations(["addClude", "removeClude"]),
    handleLayout() {
      this.$confirm("确定要退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message({
          type: "success",
          message: "安全退出登录!",
        });
        clearInterval(this.timer);
        Cookies.get("token") && Cookies.remove("token");
        storage.session.get("token") && storage.session.remove("token");
        storage.get("userInfo") && storage.remove("userInfo");
        storage.get("routes") && storage.remove("routes");
        storage.get("name_keys") && storage.remove("name_keys");
        storage.get("shopList") && storage.remove("shopList");
        storage.session.get("tabs") && storage.session.remove("tabs");
        storage.session.get("currTab") && storage.session.remove("currTab");
        this.$router.replace({
          path: "/newLogin",
        });
      });
    },
    handleRoutes(index, item, changeCurrent,type) {
      if (item.children&&type!=1) {//1子菜单过来的
        changeCurrent && (this.currIndex = index);
        this.show = true;
      } else {
        this.show = false;
        // 如果当前点击是相同的菜单
        if (this.currTab === item.key) {
          const closeTab = this.tabs.filter(
            (item) => item.key === this.currTab
          );
          const index = this.include.findIndex(
            (item) => item === closeTab.routeName
          );
          // this.removeClude({
          //   index: 0,
          // })
          this.same = true;
        }
        this.$router
          .replace({
            path: item.path,
            query: {
              t: Date.now(),
            },
          })
          .then(() => {
            this.show = false;
            this.currRoute = item;
            // 递归高亮导航栏
            this.handleActive(this.routes, item.key);
            if (this.same) {
              // console.log('跳相同页面');
              // 操作完成将this.same重置
              this.same = false;
            }
          });
      }
    },
    // 关闭标签
    handleClose(key) {
      if(this.$store.state.isFlush){
        this.$bus.$emit("flushPage",this.$store.state.isFlush);
        return;
      }

      if (this.tabs.length === 1) {
        this.$message({
          message: "最后一个标签不可关闭。",
          type: "warning",
        });
        return;
      }
      let tabs = this.tabs.filter((item) => item.key !== key);

      this.tabs = tabs;
      this.currTab = tabs[tabs.length - 1].key;

      tabs.forEach((item) => {
        if (item.key === this.currTab) {
          this.$router.replace({
            path: item.path,
            query: item.query,
          });
        }
      });
    },
    // 选择标签
    handleClick() {
      this.tabs.forEach((item) => {
        if (item.key === this.currTab) {
          this.$router.replace({
            path: item.path,
            query: item.query
          });
          // console.log(this.$route)
          // if(this.$route.query.status){
          //   this.$route.matched[this.$route.matched.length-1].components.default.created();
          //   // this.$route.matched[this.$route.matched.length-1].components.default.methods[this.$route.query.funName]()
          // }
        //   console.log(this.$route)
        // console.log()

        }
      });
    },
    // 导航栏高亮
    handleActive(arr, key) {
      arr.forEach((item) => {
        if (key.startsWith(item.key)) {
          this.$set(item, "active", true);
        } else {
          this.$set(item, "active", false);
        }
        item.children && this.handleActive(item.children, key);
      });
    },
  },
  computed: {
    ...mapState(["include"]),
  },
  watch: {
    // currTab: {
    //   handler(newVal, old) {
    //     console.log('监听', newVal);
    //   }
    // },
    hierarchy_id: {
      handler(newVal, oldVal) {
        if (oldVal) {
          // 多窗口切换门店
          this.tabs = [
            {
              key: "0",
              title: "首页",
              path: "/index",
              query: {},
            },
          ],
          storage.session.get('tabs') && storage.session.remove('tabs');
          storage.session.get('currTab') && storage.session.remove('currTab');
          this.$router.replace({
            path: '/',
          });
        }
      },
    },
    // 监听网络
    onLine: {
      handler(val) {
        if (!val) {
          this.$notify.error({
            title: "网络状态",
            message: "网络连接错误，请检查网络。",
          });
        } else {
          this.$notify.success({
            title: "网络状态",
            message: "网络恢复。",
          });
        }
      },
    },
    include: {
      handler(val) {
        // console.log(val)
      },
      immediate: true,
      deep: true,
    },
    // 监听路由
    $route: {
      handler(val) {
        if(val.query.metaTitle){
          val.meta.title=val.query.metaTitle;
        }
        if (!this.routes.length) {
          this.routes = storage.get("routes");
          this.userInfo = storage.get("userInfo");
        }

        this.num += 1;

        if (this.num === 1 && storage.session.get("tabs")) {
          const tabs = storage.session.get("tabs");
          this.tabs = tabs;
          this.currTab = storage.session.get("currTab");
          this.handleActive(this.routes, this.currTab);
          return;
        }

        const name_keys = storage.get("name_keys");
        let route= name_keys[val.path];
        if (route) {
          const keys = route.key;
          this.handleActive(this.routes, keys);
          let tabs = [...this.tabs];
          if (tabs.length) {
            // 根据meta里的key判断tabs是否存在
            let m = 0;
            tabs.forEach((item, index) => {
              if (item.key !== keys) {
                m += 1;
                // 新增tabs
                if (m === tabs.length) {
                  route.routeName = val.name;
                  route.query = val.query;
                  tabs.push(route);
                  this.currTab = keys;
                  // vuex
                  this.addClude({
                    route: val.name,
                  });
                }
              } else {
                route.routeName = val.name;
                route.query = val.query;
                tabs.splice(index, 1, route);
                // 当前的tabs已创建,替换成新的
                this.currTab = keys;
              }
            });
          } else {
            route.routeName = val.name;
            route.query = val.query;
            tabs.push(route);
            this.currTab = keys;
            // vuex
            this.addClude({
              route: val.name,
            });
          }
          this.tabs = tabs;
        } else {
          let currPath = val.matched[val.matched.length-1].path; //matched里面能拿到说明是路由模式
          if(name_keys[currPath]){
            let tabs =JSON.parse(JSON.stringify(this.tabs))
            let route={
              key: val.path,
              title: val.meta.title,
              path: val.path,
              query: val.query,
              routeName: val.path,
            }
            this.handleActive(this.routes,val.meta.title);
            let sIndex=tabs.findIndex((item, index, arr) => {
              return item.path ==val.path;
            })
            if (sIndex>-1) {
              tabs.splice(sIndex, 1, route);
              // 当前的tabs已创建,替换成新的
              this.currTab = val.path;
            } else {

              this.tabs.push(route);
              this.currTab = val.path;
              this.addClude({
                route: val.path,
              });

            }
          }else{
            let num = 0;
            this.tabs.forEach((item) => {
              if (item.key != '-99') {
                num += 1;
              }
            })
            if (num === this.tabs.length) {
                this.tabs.push({
                key: "-99",
                title: "无权限",
                path: "/403",
                query: {},
              });
            }
            this.currTab = "-99";
            this.handleActive(this.routes, "");
            this.$router.replace({
              path: "/403",
            });
          }
        }
        storage.session.set("tabs", this.tabs);
        storage.session.set("currTab", this.currTab);
      },
      // 深度观察监听
      immediate: true,
      deep: true,
    },
  },
  created() {
    document.closetab=this.closetab;
    document.previousPageTab=this.previousPageTab;
    let fullWidth = window.screen.width;
    if (fullWidth < 1200) {
      fullWidth = 1200;
    }
    this.width = fullWidth - 80;
    // this.routes = routes[0].children;
    this.timer = setInterval(() => {
      this.userInfo = storage.get("userInfo");
      this.hierarchy_id = this.userInfo.hierarchy_id;
      this.token = storage.session.get("token") || Cookies.get("token");
    }, 1000);
  },
  mounted() {
    let rightDom = this.$refs.right;
    this.rightWidth = rightDom.offsetWidth;
    // 检测网络
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    // 获取门店列表
    getShopListReq().then((res) => {
      if (res.code === 1) {
        if (res.data.length > 0) {
          storage.session.set("shopList", res.data);
          this.canChange = true;
        }
      }
    });
  },
  beforeRouteEnter(to, from, next) {
    if(to.query.metaTitle){
      to.meta.title=to.query.metaTitle;
    }
    const name_keys = {
      "/": { path: "/index", title: "主页" },
      "/newLogin": { path: "/newLogin", title: "登录" },
      "/403": { key: "-99", title: "无权限", path: "/403", query: {} },
    };
    const currPath = to.matched[to.matched.length-1].path;
    function handleArr(arr, key) {
      let newArr = [...arr];
      newArr.forEach((item, index) => {
        if (key) {
          item.key = `${key}-${index}`;
        } else {
          item.key = `${index}`;
        }
        name_keys[item.path] = item;
        item.children && handleArr(item.children, item.key);
      });
      storage.set("name_keys", name_keys);
      return newArr;
    }
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    const token = storage.session.get("token") || Cookies.get("token");
    if (!token) {
      next({ name: "NewLogin" });
    } else {
      myMenuReq()
        .then((res) => {

          if (res.code === 1) {
            const data = res.data;
            const routes = handleArr(data.menu, "");
            storage.set("routes", routes);
            storage.set("userInfo", data.user_info);
            if (name_keys[currPath]) {
              next();
            } else {
              next("/403");
            }
          }
        })
        .catch(() => {
          next({ name: "NewLogin" });
        });
    }
  },
};
</script>

<style scoped lang="less">
.right{
  width: calc(100% - 80px);
  min-width: 1024px;
  overflow: auto;

}
.change {
  cursor: pointer;
  margin-left: 10px;
  .iconfont {
    font-size: 22px;
    color: rgb(144, 147, 153);
  }
}
/deep/ .el-tabs__item .el-icon-close:before {
  transform: scale(1.2);
}
// 左侧导航栏宽度为84px
// 右侧头部高度为65px
/deep/ .el-image {
  overflow: initial;
}
.content {
  .right-header-tabs {
    width: calc(100% - 480px);
    /deep/ .el-tabs__item {
      margin: 0 4px;
    }
    /deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
      border: none;
    }
    /deep/ .el-tabs--card > .el-tabs__header .el-tabs__item {
      transition: all 0.3s;
      text-align: center;
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      color: #90949a;
      border: 1px solid #f0f1f5;
      border-bottom: none;
      border-radius: 10px 10px 0px 0px;
    }
    /deep/ .el-tabs__header {
      margin: 0;
    }
    /deep/ .el-tabs--card > .el-tabs__header {
      border: none;
    }
    /deep/ .el-tabs__item.is-active {
      border: 1px solid #ddd !important;
      border-bottom: none !important;
      color: #343434 !important;
      background: #f6f7fb;
    }
    /deep/ .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
      border-left: 1px solid #f0f1f5;
      // .el-icon-close {
      //   display: none;
      // }
    }
  }
}

.slide {
  transform: translateX(0) !important;
}
.item-active2 {
  // color: #5493F5 !important;
  font-weight: bold;
}
.item-active3 {
  color: #5493f5 !important;
}
.wrapper {
  display: flex;
  font-size: 16px;
  font-family: "PingFang";
  .left {
    width: 80px;
    flex-shrink: 0;
    height: 100vh;
    background: #2791ff;
    z-index: 102;
    padding: 23px 0px;
    box-sizing: border-box;
    position: fixed;
    &-logo {
      width: 58px;
      height: 58px;
      border-radius: 10px;
      // background: #ccc;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-list {
      padding: 20px 0;
      overflow-y: auto;
      height: calc(100vh - 104px);
      box-sizing: border-box;
      &::-webkit-scrollbar {
        display: none;
      }
      &-item {
        height: 86px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        &-icon {
          width: 22px;
          height: 22px;
          margin-bottom: 10px;
          line-height: 22px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &-title {
          color: white;
          text-align: center;
          font-size: 14px;
        }
        &:hover {
          background: #007dff;
          color: white;
        }
      }
      .item-active1 {
        background: #007dff;
        color: white;
      }
    }
    &-txt {
      position: absolute;
      bottom: 20px;
      text-align: center;
      font-size: 12px;
      color: white;
      left: 0;
      right: 0;
      z-index: 99999;
      background: #2791ff;
    }
  }
  .drawer {
    &-mask {
      position: fixed;
      top: 0;
      left: 80px;
      right: 0;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      z-index: 100;
      visibility: hidden;
      transition: all 0.3s;
      opacity: 0;
      &-show {
        visibility: visible;
        opacity: 1;
      }
    }
    &-list {
      position: fixed;
      top: 0;
      left: 80px;
      height: 100vh;
      width: 190px;
      background: white;
      z-index: 101;
      padding: 40px 20px;
      box-sizing: border-box;
      overflow-y: auto;
      transform: translateX(-100% - 84px);
      transition: all 0.4s;
      &::-webkit-scrollbar {
        display: none;
      }
      &-item {
        padding: 20px 22px 4px 22px;
        box-sizing: border-box;
        border-bottom: 1px solid #e4e7ed;
        &-header {
          font-size: 16px;
          color: #2b343d;
          font-family: "PingFangM";
          position: relative;
          white-space: nowrap;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #5493f5;
            top: 10px;
            left: -10px;
          }
        }
        .box {
          margin-top: 19px;
          .drawer-list-item-txt {
            font-size: 14px;
            color: #2b343d;
            margin-bottom: 14px;
            cursor: pointer;
            white-space: nowrap;
            &:hover {
              color: #5493f5;
            }
          }
        }
      }
    }
  }
  .right {
    // background: rgb(240,242,245);
    background: #F5F7FA;
    margin-left: 80px;
    flex-shrink: 0;
    &-header {
      height: 65px;
      width: 100%;
      background: white;
      display: flex;
      border-bottom: 1px solid #ddd;
      box-sizing: border-box;
      &-tabs {
        flex: 1;
        margin-right: 10px;
        margin-top: 20px;
        margin-left: 6px;
      }
      &-msg {
        // min-width: 363px;
        display: flex;
        align-items: center;
        border-left: 4px solid rgb(250, 250, 250);
        padding-left: 20px;
        box-sizing: border-box;
        justify-content: space-between;
        .user {
          display: flex;
          align-items: center;
          padding-right: 15px;
          transition: all 0.3s;
          &-avatar {
            width: 47px;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 47px;
              height: 47px;
              border-radius: 50%;
              border: 1px solid #eee;
            }
          }
          &-name {
            font-size: 16px;
            margin: 0 7px 0 10px;
          }
          &-desc {
            font-size: 12px;
            color: #90949a;
          }
        }
        .options {
          display: flex;
          // width: 155px;
          justify-content: space-between;
          border-left: 1px solid #e0e0e0;
          padding: 0 20px;
          box-sizing: border-box;
          &-item1 {
            width: 23px;
            height: 23px;
            line-height: 23px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &-item2 {
            width: 19px;
            height: 24px;
            line-height: 24px;
            cursor: pointer;
            position: relative;
            .point {
              position: absolute;
              background: #fd563a;
              height: 16px;
              border-radius: 999px;
              color: white;
              line-height: 16px;
              text-align: center;
              top: -8px;
              left: 8px;
              font-size: 12px;
              padding: 0 6px;
              box-sizing: border-box;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
          &-item3 {
            width: 19px;
            height: 20px;
            line-height: 20px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    &-content {
      height: calc(100vh - 65px);
      padding: 10px;
      padding-bottom: 0;
      box-sizing: border-box;
      overflow-y: auto;
      font-size: 14px;
      &-wrapper {
        // background: white;
      }
    }
  }
}
</style>
