import Vue from 'vue';
import ElementUI from 'element-ui';

import { get, post } from '@/utils/request';
import nodeWrap from '@/components/nodeWrap';
import addNode from '@/components/addNode';
import App from './App.vue';
import router from './router';
import store from './store';
// import 'element-ui/lib/theme-chalk/index.css';
import './plugins/axios';
import './plugins/element';
import './css/override-element-ui.css';
import { UTable, UTableColumn, UxGrid, UxTableColumn } from 'umy-ui';
import 'umy-ui/lib/theme-chalk/index.css';// 引入样式
import func from './plugins/preload';
import ydc from './plugins/common';
import Print from 'vue-print-nb'
import Clipboard from 'v-clipboard'
import VueClipboard  from 'vue-clipboard2'

import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.XEUtils = Vue.prototype.$XEUtils = XEUtils;
Vue.use(VXETable)
Vue.use(VueClipboard);
Vue.use(Clipboard);
Vue.use(Print); //注册
Vue.use(nodeWrap);
Vue.component('nodeWrap', nodeWrap); // 初始化组件
Vue.use(addNode);
Vue.component('addNode', addNode); // 初始化组件
Vue.prototype.$func = func;
Vue.prototype.$ydc = ydc;

// 全局select-tree组件
import SelectTree from '@/components/treeSelect';
Vue.component('SelectTree', SelectTree); // 初始化组件

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.component(UTable.name, UTable);
Vue.component(UTableColumn.name, UTableColumn);
Vue.component(UxGrid.name, UxGrid);
Vue.component(UxTableColumn.name, UxTableColumn);
Vue.prototype.$get = get;
Vue.prototype.$post = post;

const g2plot = require('@antv/g2plot') // 1. 引入g2plot
Vue.prototype.$g2plot = g2plot // 2. 将g2plot挂载到vue中
// Vue.directive('enterNumber', {
//   inserted: function (el) {
//     el.addEventListener("keypress",function(e){
//       e = e || window.event;
//       let charcode = typeof e.charCode == 'number' ? e.charCode : e.keyCode;
//       let re = /\d/;
//       if(!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey){
//           if(e.preventDefault){
//               e.preventDefault();
//           }else{
//               e.returnValue = false;
//           }
//       }
//     });
//   }
// });

let EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
    $bus: {
        get: function () {
            return EventBus
        }
    }
})

Vue.directive('enterNumber', {
  bind: function (el, { value = 2 }) {
    el = el.nodeName == "INPUT" ? el : el.children[0]
    var RegStr = value == 0 ? `^[\\+\\-]?\\d+\\d{0,0}` : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`;
    el.addEventListener('keyup', function () {
      el.value = el.value.match(new RegExp(RegStr, 'g'));
      el.dispatchEvent(new Event('input'))
    })
  }
});

Vue.directive('focus', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el) {
    el.focus()
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  components: {
    nodeWrap,
    addNode,
  },
}).$mount('#app');
