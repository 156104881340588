import BasicLayout from '../views/BasicLayout.vue';
// import Login from '../views/Login.vue';
// import noAuthority from '../views/noAuthority/index.vue';
// import Index from '../views/index/Index.vue';
// import Authority from '../views/authority/Index.vue';
// import Manage from '../views/authority/manage/Index.vue';
// import Config from '../views/authority/config/Index.vue';
// import System from '../views/system/Index.vue';
// import Agent from '../views/agent/index.vue';
// import AuthorityMenage from '../views/system/authorityMenage/Index.vue';
// import AuthorityList from '../views/system/authorityMenage/authorityList/Index.vue';
// import AgentManange from '../views/agent/agentManagement/index.vue';
// import AgentList from '../views/agent/agentManagement/agentList/index.vue';
// import FollowUpManage from '../views/agent/freeFollowupManagement/index.vue';
// import FollowUpList from '../views/agent/freeFollowupManagement/followUpList/index.vue';
// import BusinessManage from '../views/agent/businessManagement/index.vue';
// import BusinessList from '../views/agent/businessManagement/businessList/index.vue';
// import DeviceList from '../views/agent/businessManagement/deviceList/index.vue';
// import StoreList from '../views/agent/businessManagement/storeList/index.vue';

const routes = [
  {
    path: '/',
    name: 'BasicLayout',
    component: BasicLayout,
    redirect: '/index',
    children: [

      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/index/Index.vue'),
        meta: {
          title: '主页',
          icon: 'icon_home',
        },
      },
      {
        path: '/403',
        name: 'noAuthority',
        component: () => import('../views/noAuthority/index.vue'),
        meta: {
          title: '无权限',
        },
      },
      {
        path: '/authority',
        name: 'Authority',
        redirect: '/authority/menage',
        component: () => import('../views/authority/Index.vue'),
        meta: {
          title: '权限',
          icon: 'icon_authority',
        },
        children: [
          {
            path: '/authority/menage',
            name: 'Manage',
            component: () => import('../views/authority/manage/Index.vue'),
            meta: {
              title: '登录账号管理',
            },
          },
          {
            path: '/authority/config',
            name: 'Config',
            component: () => import('../views/authority/config/Index.vue'),
            meta: {
              title: '配置权限',
            },
          },
        ],
      },
      {
        path: '/authorit',
        name: 'Authorit',
        component: () => import('../views/authorit/Index.vue'),
        redirect: '/system/authorityMenage',
        meta: {
          title: '权限',
          icon: 'icon_system',
        },
        children: [
          {
            path: '/authorit/authorityMenage',
            name: 'AuthorityMenage',
            redirect: '/authorit/authorityMenage/authorityList',
            component: () => import('../views/authorit/authorityMenage/Index.vue'),
            meta: {
              title: '权限管理',
            },
            children: [
              {
                path: '/authorit/authorityMenage/authorityList',
                name: 'AuthorityList',
                component: () => import('../views/authorit/authorityMenage/authorityList/Index.vue'),
                meta: {
                  title: '权限列表',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/agent',
        name: 'Agent',
        component: () => import('../views/agent/index.vue'),
        meta: {
          title: '代理',
          icon: 'icon_proxy',
        },
        children: [
          {
            path: '/agent/AgentManage',
            name: 'AgentManage',
            redirect: '/agent/AgentManage',
            component: () => import('../views/agent/agentManagement/index.vue'),
            meta: {
              title: '代理商管理',
            },
            children: [
              {
                path: '/agent/AgentManage/list',
                name: 'AgentList',
                component: () => import('../views/agent/agentManagement/agentList/index.vue'),
                meta: {
                  title: '代理商列表',
                },
              },
            ],
          },
          {
            path: '/follow/FollowManage',
            name: 'FollowManage',
            redirect: '/follow/FollowManage/list',
            component: () => import('../views/agent/freeFollowupManagement/index.vue'),
            meta: {
              title: '跟进使用管理',
            },
            children: [
              {
                path: '/follow/FollowManage/list',
                name: 'FollowList',
                component: () => import('../views/agent/freeFollowupManagement/followUpList/index.vue'),
                meta: {
                  title: '免费使用列表',
                },
              },
            ],
          },
          {
            path: '/business/businessManage',
            name: 'BusinessManage',
            redirect: '/business/businessManage',
            component: () => import('../views/agent/businessManagement/index.vue'),
            meta: {
              title: '商家管理',
            },
            children: [
              {
                path: '/business/businessManage/businessList',
                name: 'businessList',
                component: () => import('../views/agent/businessManagement/businessList/index.vue'),
                meta: {
                  title: '商家列表',
                },
              },
              {
                path: '/business/businessManage/storeList',
                name: 'storeList',
                component: () => import('../views/agent/businessManagement/storeList/index.vue'),
                meta: {
                  title: '门店列表',
                },
              },
              {
                path: '/business/businessManage/deviceList',
                name: 'deviceList',
                component: () => import('../views/agent/businessManagement/deviceList/index.vue'),
                meta: {
                  title: '设备列表',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/goods',
        name: '/Goods',
        redirect: '/goods/goodsConfig',
        component: () => import('../views/goods/Index.vue'),
        meta: {
          title: '货品',
          icon: '',
        },
        children: [
          {
            path: '/goods/goodsConfig',
            name: 'GoodsConfig',
            redirect: '/goods/goodsConfig/classify',
            component: () => import('../views/goods/goodsConfig/Index.vue'),
            meta: {
              title: '货品配置',
            },
            children: [
              {
                path: '/goods/goodsConfig/classify',
                name: 'Classify',
                component: () => import('../views/goods/goodsConfig/classify/Index.vue'),
                meta: {
                  title: '货品分类',
                },
              },
              {
                path: '/goods/goodsConfig/config',
                name: 'Configs',
                component: () => import('../views/goods/goodsConfig/config/Index.vue'),
                meta: {
                  title: '属性配置',
                },
              },
              {
                path:'/goods/safeguard',
                name: 'safeguard',
                component: () => import('../views/goods/safeguard'),
                meta: {
                  title: '单据类型维护',
                },
              },
            ],
          },
          {
            path: '/goods/goodsWarehousing',
            name: 'WareHousing',
            redirect: '/goods/goodsWarehousing/list',
            component: () => import('../views/goods/goodsWarehousing/Index.vue'),
            meta: {
              title: '货品入库',
            },
            children: [
              {
                path: '/goods/godownEntry/godownEntryList',
                name: 'godownEntryList',
                component: () => import('../views/goods/godownEntry/godownEntryList.vue'),
                meta: {
                  title: '入库单列表',
                },
              },
              {
                path: '/goods/godownEntry/Details/:id?',
                name: 'godownEntryListDetails',
                component: () => import('../views/goods/godownEntry/godownEntryListDetails.vue'),
                meta: {
                  title: '入库单详情',
                },
                props:true
              },
              {
                path: '/goods/goodsWarehousing/list',
                name: 'WareHousingList',
                component: () => import('../views/goods/goodsWarehousing/list/Index.vue'),
                meta: {
                  title: '入库列表',
                },
              },
              {
                path: '/goods/goodsWarehousing/modifyList',
                name: 'ModifyList',
                component: () => import('../views/goods/goodsWarehousing/modifyList/Index.vue'),
                meta: {
                  title: '修改单列表',
                },
              },
            ],
          },
          {
            path: '/goods/goodsHandler',
            name: 'goodsHandler',
            redirect: '/goods/goodsHandler/old',
            component: () => import('../views/goods/goodsHandler/Index.vue'),
            meta: {
              title: '货品处理',
            },
            children: [
              {
                path: '/goods/goodsHandler/old',
                name: 'Old',
                component: () => import('../views/goods/goodsHandler/old/Index.vue'),
                meta: {
                  title: '旧料处理',
                },
              },
              {
                path: '/goods/goodsHandler/change',
                name: 'OldList',
                component: () => import('../views/goods/goodsHandler/change/Index.vue'),
                meta: {
                  title: '拆改单列表',
                },
              },
              {
                path: '/goods/goodsHandler/stock',
                name: 'StockList',
                component: () => import('../views/goods/goodsHandler/stock/Index.vue'),
                meta: {
                  title: '退库列表',
                },
              },
              {
                path: '/goods/goodsHandler/newToOld',
                name: 'newToOldList',
                component: () => import('../views/goods/goodsHandler/newToOld/Index.vue'),
                meta: {
                  title: '新品转旧料',
                },
              },
            ],
          },
          {
            path: '/goods/goodsStock',
            name: 'Stock',
            redirect: '/goods/goodsStock/goodsDetailedlist',
            component: () => import('../views/goods/goodsStock/Index.vue'),
            meta: {
              title: '货品库存',
            },
            children: [
              {
                path: '/goods/goodsOldDetailed/list',
                name: 'GoodsOldDetailed',
                component: () => import('../views/goods/goodsStock/goodsOldDetailedList/Index.vue'),
                meta: {
                  title: '旧料清单列表',
                },
              },
              {
                path: '/goods/historyInv/index',
                name: 'HistoryInv',
                component: () => import('../views/goods/historyInv/Index.vue'),
                meta: {
                  title: '历史库存',
                },
              },
              {
                path: '/goods/rawHistoryInv/index',
                name: 'HistoryInv',
                component: () => import('../views/goods/rawHistoryInv/Index.vue'),
                meta: {
                  title: '旧料历史库存',
                },
              },
              {
                path: '/goods/goodsDetailed/list',
                name: 'GoodsDetailed',
                component: () => import('../views/goods/goodsStock/goodsDetailedlist/list/Index.vue'),
                meta: {
                  title: '货品清单列表',
                },
              },
              {
                path: '/goods/storeGoodsDetailed/list',
                name: 'StoreGoodsDetailed',
                component: () => import('../views/goods/goodsStock/goodsDetailedlist/list/storeList.vue'),
                meta: {
                  title: '门店货品列表',
                },
              },
              {
                path: '/goods/goodsSearch/list',
                name: 'StoreSearch',
                component: () => import('../views/goods/goodsSearch/Index.vue'),
                meta: {
                  title: '货品搜索',
                },
              },
              {
                path: '/goods/goodsInventory/list',
                name: 'InventoryList',
                component: () => import('../views/goods/goodsStock/goodsInventory/list/index.vue'),
                meta: {
                  title: '货品盘点列表',
                },
              },
              {
                path: '/goods/raw/goldList',
                name: 'goldList',
                component: () => import('../views/goods/raw/goldList/Index.vue'),
                meta: {
                  title: '金料清单',
                },
              },
              {
                path: '/goods/raw/goldList',
                name: 'goldList',
                component: () => import('../views/goods/raw/goldList/Index.vue'),
                meta: {
                  title: '金料清单',
                },
              },
              {
                path: '/goods/raw/out',
                name: 'Out',
                component: () => import('../views/goods/raw/out/Index.vue'),
                meta: {
                  title: '金料出库单',
                },
              },
              {
                path: '/goods/raw/in',
                name: 'In',
                component: () => import('../views/goods/raw/in/Index.vue'),
                meta: {
                  title: '金料入库单',
                },
              },
              {
                path: '/goods/raw/toGold',
                name: 'toGold',
                component: () => import('../views/goods/raw/toGold/Index.vue'),
                meta: {
                  title: '旧料转金料',
                },
              },
            ],
          },
          {
            path: '/goods/RFID',
            name: 'RFID',
            redirect: '/goods/RFID/list',
            component: () => import('../views/goods/RFID/Index.vue'),
            meta: {
              title: 'RFID管理',
            },
            children: [
              {
                path: '/goods/RFID/list',
                name: 'RFIDlist',
                component: () => import('../views/goods/RFID/list/Index.vue'),
                meta: {
                  title: 'RFID列表',
                },
              },
            ],
          },
          {
            path: '/goods/label',
            name: 'Label',
            component: () => import('../views/goods/label/Index.vue'),
            meta: {
              title: '生成标签',
            },
          },
          {
            path: '/goods/goodsStore',
            name: 'Store',
            redirect: '/goods/goodsStore/list',
            component: () => import('../views/goods/goodsStore/Index.vue'),
            meta: {
              title: '货品仓库',
            },
            children: [
              {
                path: '/goods/goodsStore/list',
                name: 'WarehouseList',
                component: () => import('../views/goods/goodsStore/list/Index.vue'),
                meta: {
                  title: '仓库列表',
                },
              },
            ],
          },
          {
            path: '/goods/goodsTransfer',
            name: 'Transfer',
            redirect: '/goods/goodsTransfer/transfer',
            component: () => import('../views/goods/goodsTransfer/Index.vue'),
            meta: {
              title: '货品调动',
            },
            children: [
              {
                path: '/goods/goodsTransfer/transfer',
                name: 'TransferOut',
                component: () => import('../views/goods/goodsTransfer/transfer/Index.vue'),
                meta: {
                  title: '调动',
                },
              },
            ],
          },
          {
            path: '/goods/adjustment',
            name: 'Adjustment',
            component: () => import('../views/goods/adjustment/Index.vue'),
            meta: {
              title: '调仓',
            },
          },
        ],
      },
      {
        path: '/member',
        name: 'member',
        redirect: '/member/memberList',
        component: () => import('../views/member/memberManage/Index.vue'),
        meta: {
          title: '会员',
          icon: '',
        },
        children: [
          {
            path: '/member/memberList',
            redirect: '/member/memberList/index',
            component: () => import('../views/member/memberManage/memberList/index.vue'),
            meta: {
              title: '会员管理',
              icon: '',
            },
            children: [
              {
                path: '/member/memberList/list',
                name: 'memberList',
                component: () => import('../views/member/memberManage/wholeMember/Index.vue'),
                meta: {
                  title: '会员列表',
                  icon: '',
                },
              },
              {
                path: '/member/waitAssignMember/list',
                name: 'myMemberList',
                component: () => import('../views/member/memberManage/waitAssignMember/Index.vue'),
                meta: {
                  title: '待分配会员',
                  icon: '',
                },
              },
              {
                path: '/member/memberList/enterprise',
                name: 'enterprise',
                component: () => import('../views/member/memberManage/enterprise/index.vue'),
                meta: {
                  title: '企微会员',
                  icon: '',
                },
              },
              {
                path: '/member/memberList/myMemberList',
                name: 'myMemberList',
                component: () => import('../views/member/memberManage/mineMember/Index.vue'),
                meta: {
                  title: '企微会员',
                  icon: '',
                },
              },
              {
                path: '/member/memberList/commonList',
                name: 'commonMemberList',
                component: () => import('../views/member/memberManage/commonMember/Index.vue'),
                meta: {
                  title: '公海会员',
                  icon: '',
                },
              },
              {
                path: '/member/memberList/assistMemberList',
                name: 'assistMemberList',
                component: () => import('../views/member/memberManage/assistMember/Index.vue'),
                meta: {
                  title: '协助会员',
                  icon: '',
                },
              },
              {
                path: '/member/memberList/assignedMemberList',
                name: 'assignedMemberList',
                component: () => import('../views/member/memberManage/assignedMember/Index.vue'),
                meta: {
                  title: '已分配会员',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/member/memberSet',
            redirect: '/member/memberSet',
            component: () => import('../views/member/Index.vue'),
            meta: {
              title: '会员设置',
              icon: ''
            },
            children: [
              {
                path: '/member/ruleSet/index',
                name: 'memberRuleSet',
                component: () => import('../views/member/ruleSet/Index.vue'),
                meta: {
                  title: '会员规则设置',
                  icon: ''
                }
              },
              {
                path: '/member/followRecordSet/index',
                name: 'followRecordSet',
                component: () => import('../views/member/followRecordSet/Index.vue'),
                meta: {
                  title: '跟进记录设置',
                  icon: ''
                }
              }
            ]
          },
          {
            path: '/member/tabManage',
            redirect: '/member/tabManage/index',
            component: () => import('../views/member/tabManage/Index.vue'),
            meta: {
              title: '标签管理',
              icon: '',
            },
            children: [
              {
                path: '/member/tabManage/tabSetting/index',
                name: 'tabSetting',
                component: () => import('../views/member/tabManage/tabSetting/Index.vue'),
                meta: {
                  title: '标签设置',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/member/savingsSet',
            redirect: '/member/savingsSet/index',
            component: () => import('../views/member/savingsSet/Index.vue'),
            meta: {
              title: '储值金设置',
              icon: '',
            },
            children: [
              {
                path: '/member/savingsSet/list',
                name: 'savingsSet',
                component: () => import('../views/member/savingsSet/list.vue'),
                meta: {
                  title: '储值金使用规则',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/member/system',
            redirect: '/member/system/Index.vue',
            component: () => import('../views/member/system/Index.vue'),
            meta: {
              title: '会员体系管理',
              icon: '',
            },
            children: [
              {
                path: '/member/system/memberSystemList/index',
                name: 'memberSystemList',
                component: () => import('../views/member/system/memberSystemList/Index.vue'),
                meta: {
                  title: '会员体系列表',
                  icon: '',
                },
              },
              {
                path: '/member/system/memberSystemBindList/index',
                name: 'memberSystemBindList',
                component: () => import('../views/member/system/memberBinding/Index.vue'),
                meta: {
                  title: '会员体系绑定列表',
                  icon: '',
                },
              },
              {
                path: "/member/system/customerService/setUp",
                name: "customerServiceSetup",
                component: () => import("../views/member/customerService/Index.vue"),
                meta: {
                  title: "客服设置",
                  icon: ''
                }
              }
            ]
          },
          {
            path: '/member/integralSetting',
            redirect: '/member/integralSetting/index',
            component: () => import('../views/member/integralSetting/Index.vue'),
            meta: {
              title: '积分设置',
              icon: '',
            },
            children: [
              {
                path: '/member/integralSetting/integralGet/index',
                name: 'integralGet',
                component: () => import('../views/member/integralSetting/integralGet/Index.vue'),
                meta: {
                  title: '积分获取',
                  icon: '',
                },
              },
              {
                path: '/member/integralSetting/integralUse/index',
                name: 'integralUse',
                component: () => import('../views/member/integralSetting/integralUse/Index.vue'),
                meta: {
                  title: '积分使用',
                  icon: '',
                },
              },
              {
                path: '/member/integralSetting/memberLevel/index',
                name: 'Level',
                component: () => import('../views/member/integralSetting/memberLevel/Index.vue'),
                meta: {
                  title: '会员等级',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/member/weChatSet',
            redirect: '/member/weChatSet/index',
            component: () => import('../views/member/publicManage/Index.vue'),
            meta: {
              title: '公众号设置',
              icon: '',
            },
            children: [
              {
                path: '/member/weChatSet/list/index',
                name: 'weChatSet',
                component: () => import('../views/member/publicManage/list/Index.vue'),
                meta: {
                  title: '公众号列表',
                  icon: '',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/system',
        name: 'System',
        redirect: '/system/ruleSetting',
        component: () => import('../views/system/Index.vue'),
        meta: {
          title: '系统',
          icon: '',
        },
        children: [
          {
            path: '/system/ruleSetting',
            name: 'ruleSetting',
            redirect: '/system/ruleSetting/integralRule',
            component: () => import('../views/system/ruleSetting/IntegralRule/Index.vue'),
            meta: {
              title: '规则设置',
              icon: '',
            },
            children: [
              {
                path: '/system/ruleSetting/integralRule/list',
                name: 'integralRule',
                component: () => import('../views/system/ruleSetting/IntegralRule/Index.vue'),
                meta: {
                  title: '积分规则',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/system/formula/index',
            name: 'Formula',
            redirect: '/system/formula/old',
            component: () => import('../views/system/formula/Index.vue'),
            meta: {
              title: '公式配置',
              icon: '',
            },
            children: [
              {
                path: '/system/formula/old',
                name: 'FormulaOld',
                component: () => import('../views/system/formula/old/Index.vue'),
                meta: {
                  title: '回收公式',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/system/work',
            name: 'SystemWork',
            redirect: '/system/work/approval',
            component: () => import('../views/system/work/Index.vue'),
            meta: {
              title: '办公',
              icon: '',
            },
            children: [
              {
                path: '/system/work/approval',
                name: 'Approval',
                component: () => import('../views/system/work/approval/newIndex.vue'),
                meta: {
                  title: '审批设置',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/system/personSetting',
            name: 'PersonSetting',
            redirect: '/system/personSetting/accountSetting',
            component: () => import('../views/system/personSetting/Index.vue'),
            meta: {
              title: '个人设置',
              icon: '',
            },
            children: [
              {
                path: '/system/personSetting/accountSetting',
                name: 'AccountSetting',
                component: () => import('../views/system/personSetting/accountSetting/Index.vue'),
                meta: {
                  title: '账号设置',
                  icon: '',
                },
              },
              {
                path: '/system/personSetting/init',
                name: 'Init',
                component: () => import('../views/system/init/Index.vue'),
                meta: {
                  title: '初始化设置',
                  icon: '',
                },
              },
              {
                path: '/system/printing',
                name: 'Printing',
                component: () => import('../views/system/printing/Index.vue'),
                meta: {
                  title: '打印模板设置',
                  icon: '',
                },
              },
              {
                path: '/system/customPrint/list',
                name: 'customPrintList',
                component: () => import('../views/system/customPrint/List.vue'),
                meta: {
                  title: '自定义打印列表',
                  icon: '',
                },
              },
              {
                path:'/system/electronic',
                name:'electronic',
                component:()=>import('../views/system/electronic'),
                meta:{
                  title:'电子证书',
                  icon:'',
                }
              },
              {
                path:'/system/electronic/addElectronic/:id?',
                name:'addElectronic',
                component:()=>import('../views/system/electronic/addElectronic.vue'),
                meta:{
                  title:'新增电子证书',
                  icon:'',
                },
                props:true
              },
            ],
          },
          {
            path: '/system/help',
            name: 'Help',
            redirect: '/system/help/suggestion',
            component: () => import('../views/system/personSetting/Index.vue'),
            meta: {
              title: '帮助',
              icon: '',
            },
            children: [
              {
                path: '/system/help/suggestion',
                name: 'Suggestion',
                component: () => import('../views/system/help/suggestion/Index.vue'),
                meta: {
                  title: '意见反馈',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/system/cashConfig',
            name: 'CashConfig',
            redirect: '/system/cashConfig/settleConfig',
            component: () => import('../views/system/cashConfig/Index.vue'),
            meta: {
              title: '收银设置',
              icon: '',
            },
            children: [
              {
                path: '/system/cashConfig/settleConfig',
                name: 'settleConfig',
                component: () => import('../views/system/cashConfig/settleConfig/Index.vue'),
                meta: {
                  title: '结算设置',
                  icon: '',
                },
              },
              {
                path: '/system/cashConfig/methods',
                name: 'settleConfig',
                component: () => import('../views/system/cashConfig/methods/Index.vue'),
                meta: {
                  title: '支付渠道设置',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/system/goldPrice',
            name: 'GoldPrice',
            component: () => import('../views/system/goldPrice/Index.vue'),
            meta: {
              title: '今日金价',
            },
          },
          {
            path: '/system/inventoryWarning',
            name: 'InventoryWarning',
            component: () => import('../views/system/inventoryWarning/Index.vue'),
            meta: {
              title: '库存周期预警',
            },
          },
          {
            path: '/system/pricingAuthorization',
            name: 'PricingAuthorization',
            component: () => import('../views/system/pricingAuthorization/Index.vue'),
            meta: {
              title: '改价授权',
            },
          },
          {
            path: '/system/supplier',
            name: 'Supplier',
            component: () => import('../views/system/supplier/Index.vue'),
            meta: {
              title: '供应商',
            },
          },
          {
            path: '/system/storeInfo',
            name: 'StoreInfo',
            component: () => import('../views/system/storeInfo/Index.vue'),
            meta: {
              title: '门店信息',
            },
          },
          {
            path: '/system/authorization',
            name: 'authorization',
            redirect: '/system/authorization/enterprise',
            component: () => import('../views/system/authorization/enterprise/index.vue'),
            meta: {
              title: '授权管理',
              icon: '',
            },
            children: [
              {
                path: '/system/authorization/enterprise',
                name: 'enterprise',
                component: () => import('../views/system/authorization/enterprise/index.vue'),
                meta: {
                  title: '企业微信授权',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/system/systemSet',
            name: 'SystemSet',
            component: () => import('../views/system/systemSet/Index.vue'),
            meta: {
              title: '开关设置',
            },
          },
        ],
      },
      {
        path: '/marketing',
        name: 'Marketing',
        redirect: '/marketing/message',
        component: () => import('../views/marketing/Index.vue'),
        meta: {
          title: '营销',
          icon: '',
        },
        children: [
          {
            path: '/marketing/message',
            name: 'Message',
            redirect: '/marketing/message/wxMessage',
            component: () => import('../views/marketing/message/Index.vue'),
            meta: {
              title: '消息管理',
              icon: '',
            },
            children: [
              {
                path: '/marketing/message/wxMessage',
                name: 'wxMessage',
                component: () => import('../views/marketing/message/wxMessage/Index.vue'),
                meta: {
                  title: '微信消息',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/marketing/activity',
            name: 'activity',
            redirect: '/marketing/activity/index',
            component: () => import('../views/marketing/activity/Index.vue'),
            meta: {
              title: '营销活动',
              icon: '',
            },
            children: [
              {
                path: '/marketing/activity/activityList/list',
                name: 'ActivityList',
                component: () => import('../views/marketing/activity/activityList/list.vue'),
                meta: {
                  title: '活动列表',
                  icon: '',
                },
              },
              {
                path: '/marketing/writeOff/list',
                name: 'writeOffList',
                component: () => import('../views/marketing/writeOff/index.vue'),
                meta: {
                  title: '核销列表',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/marketing/coupon',
            name: 'Coupon',
            redirect: '/marketing/coupon/couponList/index',
            component: () => import('../views/marketing/coupon/Index.vue'),
            meta: {
              title: '优惠券',
              icon: '',
            },
            children: [
              {
                path: '/marketing/coupon/couponList/index',
                name: 'coupnlist',
                component: () => import('../views/marketing/coupon/couponList/index.vue'),
                meta: {
                  title: '优惠券管理',
                  icon: '',
                },
              },
              {
                path:"/marketing/coupon/sendCoupon/index",
                name:"sendCoupon",
                component:()=>import("../views/marketing/coupon/sendCoupon/index"),
                meta:{
                  title:"送券计划",
                  icon:""
                }
              },
              {
                path:"/marketing/coupon/couponCount/index",
                name:'couponCount',
                component:()=>import("../views/marketing/coupon/couponCount/index"),
                meta :{
                  title:"送券统计",
                  icon:''
                }
              },{
                path:"/marketing/coupon/analysisCoupon/index",
                name:"analysisCoupon",
                component:()=>import("../views/marketing/coupon/analysisCoupon/index"),
                meta:{
                  title:"优惠券分析",
                  icon:''
                }
              },
              {
                path:"/marketing/coupon/provideCoupont/index",
                name:"analysisCoupon",
                component:()=>import("../views/marketing/coupon/provideCoupont/index"),
                meta:{
                  title:"优惠券发放",
                  icon:''
                }
              }
            ],
          },
          {
            path: '/marketing/activity/add',
            name: 'activityAdd',
            redirect: '/marketing/activity/add',
            component: () => import('../views/marketing/activity/Index.vue'),
            meta: {
              title: '活动创建',
              icon: '',
            },
            children: [
              {
                path: '/marketing/activity/add',
                name: 'activityAdd',
                component: () => import('../views/marketing/activity/activityList/add.vue'),
                meta: {
                  title: '新建活动',
                  icon: '',
                },
              },
            ]
          },
          {
            path: '/marketing/basicActivities/index',
            name: 'basicActivities',
            redirect: '/marketing/basicActivities/signList',
            component: () => import('../views/marketing/basicActivities/Index.vue'),
            meta: {
              title: '基础活动',
              icon: '',
            },
            children: [
              {
                path: '/marketing/basicActivities/signIn/list',
                name: 'signIn',
                component: () => import('../views/marketing/basicActivities/signIn/Index.vue'),
                meta: {
                  title: '会员签到',
                  icon: '',
                },
              },
            ]
          },
          {
            path: '/marketting/integralShop',
            name: 'integralShop',
            component: () => import('../views/marketing/integralShop/Index.vue'),
            meta: {
              title: '积分商城',
              icon: ''
            },
            children: [
              {
                path: '/marketting/integralShop/shopManage',
                name: 'shopManage',
                component: () => import('../views/marketing/integralShop/shopManage/Index.vue'),
                meta: {
                  title: '商城管理',
                  icon: ''
                }
              }
            ]

          }
        ],
      },
      {
        path: '/order',
        name: 'Order',
        redirect: '/order/offlineOrder',
        component: () => import('../views/order/Index.vue'),
        meta: {
          title: '订单',
          icon: '',
        },
        children: [
          //提成列表
          {
            path:'/order/commision',
            name:'commision',
            component:()=>import('../views/order/commision'),
            meta:{
              title:'销售提成',
              icon:''
            },
            children:[
              {
                path:'/order/commision/commisionList',
                name:'commisionList',
                component:()=>import('../views/order/commision/commisionList'),
                meta:{
                  title:'销售提成表',
                  icon:'',
                }
              },
              {
                path:'/order/commision/commisionSet',
                name:'commisionSet',
                component:()=>import('../views/order/commision/commisionSet'),
                meta:{
                  title:'提成设置',
                  icon:'',
                }
              },
              {
                path:'/order/commision/commisionOld',
                name:'commisionOld',
                component:()=>import('../views/order/commision/commisionOld'),
                meta:{
                  title:'旧料提成表',
                  icon:'',
                }
              },
              {
                path:'/order/commision/commisionRanking',
                name:'commisionRanking',
                component:()=>import('../views/order/commision/commisionRanking'),
                meta:{
                  title:'销售排行榜',
                  icon:'',
                }
              },
            ]
          },
          {
            path: '/order/offlineOrder',
            name: 'OfflineOrder',
            redirect: '/order/offlineOrder/salesOrder',
            component: () => import('../views/order/offlineOrder/Index.vue'),
            meta: {
              title: '线下订单',
              icon: '',
            },
            children: [
              {
                path: '/order/offlineOrder/cutOrder',
                name: 'cutOrder',
                component: () => import('../views/order/offlineOrder/cutOrder'),
                meta: {
                  title: '截金单',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/frontOrder',
                name: 'frontOrder',
                component: () => import('../views/order/offlineOrder/frontOrder'),
                meta: {
                  title: '定金单',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/commission',
                name: 'commission',
                component: () => import('../views/order/offlineOrder/commission'),
                meta: {
                  title: '销售提成',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/salesOrder',
                name: 'offlineOrderSalesOrder',
                component: () => import('../views/order/offlineOrder/salesOrder/Index.vue'),
                meta: {
                  title: '普通订单',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/newSalesOrder',
                name: 'newSalesOrder',
                component: () => import('../views/order/offlineOrder/newSalesOrder/salesLIst.vue'),
                meta: {
                  title: '新销售订单',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/selesDetail/:id?',
                name: 'selesDetail',
                component: () => import('../views/order/offlineOrder/newSalesOrder/selesDetail.vue'),
                meta: {
                  title: '销售详情',
                },
              },
              {
                path: '/order/offlineOrder/mySalesOrder',
                name: 'offlineOrderMySalesOrder',
                component: () => import('../views/order/offlineOrder/mySalesOrder/Index.vue'),
                meta: {
                  title: '我的订单',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/returnOrder',
                name: 'offlineOrderReturnOrder',
                component: () => import('../views/order/offlineOrder/returnOrder/Index.vue'),
                meta: {
                  title: '退货订单',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/myReturnOrder',
                name: 'offlineOrderMyReturnOrder',
                component: () => import('../views/order/offlineOrder/myReturnOrder/Index.vue'),
                meta: {
                  title: '我的退货',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/exchangeOrder',
                name: 'changeOrder',
                component: () => import('../views/order/offlineOrder/exchangeOrder/Index.vue'),
                meta: {
                  title: '回收订单',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/myExchangeOrder',
                name: 'myChangeOrder',
                component: () => import('../views/order/offlineOrder/myExchangeOrder/Index.vue'),
                meta: {
                  title: '我的回收',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/redeemOrder',
                name: 'redeemOrder',
                component: () => import('../views/order/offlineOrder/redeemOrder/Index.vue'),
                meta: {
                  title: '赎回订单',
                  icon: '',
                },
              },
              {
                path: '/order/offlineOrder/myRedeemOrder',
                name: 'myRedeemOrder',
                component: () => import('../views/order/offlineOrder/myRedeemOrder/Index.vue'),
                meta: {
                  title: '我的赎回',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/order/account',
            name: 'Account',
            redirect: '/order/account/list',
            component: () => import('../views/order/account/Index.vue'),
            meta: {
              title: '财务管理',
              icon: '',
            },
            children: [
              {
                path: '/order/account/list',
                name: 'AccountList',
                component: () => import('../views/order/account/list/Index.vue'),
                meta: {
                  title: '收支明细',
                  icon: '',
                },
              },
              {
                path: '/statistics/finance/otherIncome',
                name: 'otherIncome',
                component: () => import('../views/statistics/finance/otherIncome/Index.vue'),
                meta: {
                  title: '其他收入',
                  icon: '',
                },
              },
              {
                path: '/statistics/finance/otherOutcome',
                name: 'otherOutcome',
                component: () => import('../views/statistics/finance/otherOutcome/Index.vue'),
                meta: {
                  title: '其他支出',
                  icon: '',
                },
              },
              {
                path: '/statistics/finance/streamType',
                name: 'otherIncome',
                component: () => import('../views/statistics/finance/streamType/Index.vue'),
                meta: {
                  title: '款项管理',
                  icon: '',
                },
              },
              {
                path: '/statistics/finance/summary',
                name: 'otherIncome',
                component: () => import('../views/statistics/finance/summary/Index.vue'),
                meta: {
                  title: '收支汇总',
                  icon: '',
                },
              },
              {
                path: '/statistics/finance/summaryDetail',
                name: 'summaryDetail',
                component: () => import('../views/statistics/finance/summary/Detail.vue'),
                meta: {
                  title: '收支明细',
                  icon: '',
                },
              },
              {
                path: '/order/mouthSetter/list',
                name: 'AccountList',
                component: () => import('../views/order/mouthSetter/Index.vue'),
                meta: {
                  title: '月结列表',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/order/warranty',
            name: 'Warranty',
            redirect: '/order/warranty/list',
            component: () => import('../views/order/warranty/Index.vue'),
            meta: {
              title: '质保单',
              icon: '',
            },
            children: [
              {
                path: '/order/warranty/list',
                name: 'WarrantyList',
                component: () => import('../views/order/warranty/list/Index.vue'),
                meta: {
                  title: '质保单列表',
                  icon: '',
                },
              },
              {
                path: '/order/warranty/config',
                name: 'WarrantyConfig',
                component: () => import('../views/order/warranty/config/Index.vue'),
                meta: {
                  title: '质保单配置',
                  icon: '',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/work',
        name: 'Work',
        redirect: '/work/handleWork',
        component: () => import('../views/work/Index.vue'),
        meta: {
          title: '办公',
          icon: '',
        },
        children: [
          {
            path: '/work/target',
            name: 'Target',
            redirect: '/work/target/saleTarget',
            component: () => import('../views/work/target/Index.vue'),
            meta: {
              title: '业绩目标',
              icon: '',
            },
            children: [
              {
                path: '/work/target/saleTarget',
                name: 'SaleTarget',
                component: () => import('../views/work/target/saleTarget/Index.vue'),
                meta: {
                  title: '销售目标',
                  icon: '',
                },
              },
              {
                path: '/work/target/analyse',
                name: 'Analyse',
                component: () => import('../views/work/target/analyse/Index.vue'),
                meta: {
                  title: '销售目标',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/work/ChangeShifts',
            name: 'ChangeShifts',
            redirect: '/work/ChangeShifts/list',
            component: () => import('../views/work/changeShifts/Index.vue'),
            meta: {
              title: '交接班',
              icon: '',
            },
            children: [
              {
                path: '/work/ChangeShifts/list',
                name: 'ChangeShiftsList',
                component: () => import('../views/work/changeShifts/list/Index.vue'),
                meta: {
                  title: '交接班列表',
                  icon: '',
                },
              },
            ],
          },
          {
            path: '/work/handleWork',
            name: 'HandleWork',
            redirect: '/work/handleWork/clockIn',
            component: () => import('../views/work/handleWork/Index.vue'),
            meta: {
              title: '办公',
              icon: '',
            },
            children: [
              {
                path: '/work/handleWork/clockIn',
                name: 'ClockIn',
                component: () => import('../views/work/handleWork/clockIn/Index.vue'),
                meta: {
                  title: '打卡',
                  icon: '',
                },
              },
              {
                path: '/work/handleWork/examine',
                name: 'Examine',
                component: () => import('../views/work/handleWork/examine/Index.vue'),
                meta: {
                  title: '审批',
                  icon: '',
                },
              },
              {
                path: '/work/handleWork/report',
                name: 'Report',
                component: () => import('../views/work/handleWork/report/Index.vue'),
                meta: {
                  title: '汇报',
                  icon: '',
                },
              },
              {
                path: '/work/handleWork/notice',
                name: 'Notice',
                component: () => import('../views/work/handleWork/notice/Index.vue'),
                meta: {
                  title: '公告',
                  icon: '',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/statistics',
        name: 'Statistics',
        redirect: '/statistics/list',
        component: () => import('../views/statistics/Index.vue'),
        meta: {
          title: '统计',
          icon: 'icontongji',
        },
        children: [
          {
            path: '/statistics/list',
            name: 'StatisticsList',
            redirect: '/statistics/list/member',
            component: () => import('../views/statistics/list/Index.vue'),
            meta: {
              title: '统计',
              icon: '',
            },
            children: [
              {
                path: '/statistics/list/member',
                name: 'StatisticMember',
                component: () => import('../views/statistics/list/member/Index.vue'),
                meta: {
                  title: '会员统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/order',
                name: 'StatisticOrder',
                component: () => import('../views/statistics/list/order/Index.vue'),
                meta: {
                  title: '订单统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/activity',
                name: 'StatisticActivity',
                component: () => import('../views/statistics/list/activity/Index.vue'),
                meta: {
                  title: '活动统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/stock',
                name: 'StatisticActivity',
                component: () => import('../views/statistics/list/stock/Index.vue'),
                meta: {
                  title: '库存统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/transfer',
                name: 'StatisticTransfer',
                component: () => import('../views/statistics/list/transfer/Index.vue'),
                meta: {
                  title: '调拨统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/saleStatistic',
                name: 'saleStatistic',
                component: () => import('../views/statistics/list/saleStatistic/Index.vue'),
                meta: {
                  title: '销售统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/wareHousing',
                name: 'wareHousingStatistic',
                component: () => import('../views/statistics/list/wareHousing/Index.vue'),
                meta: {
                  title: '入库统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/returnGoods',
                name: 'returnGoodsStatistic',
                component: () => import('../views/statistics/list/returnGoods/Index.vue'),
                meta: {
                  title: '退货统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/recovery',
                name: 'recoveryStatistic',
                component: () => import('../views/statistics/list/recovery/Index.vue'),
                meta: {
                  title: '回收统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/redemption',
                name: 'redemptionStatistic',
                component: () => import('../views/statistics/list/redemption/Index.vue'),
                meta: {
                  title: '赎回统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/change',
                name: 'changeStatistic',
                component: () => import('../views/statistics/list/change/Index.vue'),
                meta: {
                  title: '拆改统计',
                  icon: '',
                },
              },
              {
                path: '/statistics/list/adjustMent',
                name: 'StatisticAdjustMent',
                component: () => import('../views/statistics/list/adjustment/Index.vue'),
                meta: {
                  title: '调拨统计',
                  icon: '',
                },
              }
            ],
          },
          {
            path: '/standingBook',
            name: 'standingBook',
            redirect: '/standingBook/goods',
            component: () => import('../views/statistics/standingBook/Index'),
            meta: {
              title: '进销存',
            },
            children: [
              {
                path: '/standingBook/goods',
                name: 'standingBook_goods',
                component: () => import('../views/statistics/standingBook/goods/Index'),
                meta: {
                  title: '品类进销存',
                },
              },
              {
                path: '/standingBook/golds',
                name: 'standingBook_golds',
                component: () => import('../views/statistics/standingBook/golds/Index'),
                meta: {
                  title: '金料进销存',
                },
              },
              {
                path: '/standingBook/raws',
                name: 'standingBook_raws',
                component: () => import('../views/statistics/standingBook/raws/Index'),
                meta: {
                  title: '旧料进销存',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../views/Login.vue'),
  // },
  {
    path: '/newLogin',
    name: 'NewLogin',
    component: () => import('../views/newLogin.vue'),
  },

];

export default routes;
