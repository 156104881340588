/* eslint-disable */
import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';
import storage from 'good-storage';
import { Message } from 'element-ui';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

// let preStatus = -1; // 判断是否是同时请求都发生 401
// 错误处理函数
const errHandle = (status) => {
  if (status === 401) {
    // 跳去登录页面
    // 清除cookie
  }
  Message.error(codeMessage[status]);
};

// baseUrl
const env = process.env.NODE_ENV;
let baseUrl;
const host = window.location.host;
// const baseUrl = (env === 'development' ? '/dev' : 'https://api.etouch.top');
if (env === 'development') {
  baseUrl = '/dev'
} else {
  console.log(host)
  if (host === 'www.etouch.top') {
    // 正式
    baseUrl = 'https://api.etouch.top'
  }
  if (host === 'edianbao.pretest.etouch.vip') {
    // 预发
    baseUrl = 'https://api.pretest.etouch.vip'
  }
  if (host === 'edianbao.test.etouch.vip') {
    // 测试
    baseUrl = 'https://api.test.etouch.vip'
  }
  if (host === 'edianbao.dev.etouch.vip') {
    // 开发
    baseUrl = 'http://api.dev.etouch.vip'
  }
}

// baseUrl = 'https://api.pretest.etouch.vip'

// 创建一个axios实例
const instance = axios.create({
  baseURL: baseUrl,
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = storage.session.get('token') || Cookies.get('token');
    // eslint-disable-next-line no-param-reassign
    token && (config.headers.Authorization = token);
    // 请求前想做的事,例如：
    // config.headers.uid = 1;
    return config;
  },
  (error) => Promise.error(error)
);
// 响应拦截器
instance.interceptors.response.use(
  res => {
    // preStatus = res.status
    // 请求成功，但后端返回错误标识；
    res['Access-Control-Expose-Headers'] = "Authorization"
    const headers = res.headers;
    // console.log(headers);
    // 更新token
    if (headers.authorization) {
      Cookies.get('token') && Cookies.set('token', headers.authorization, { expires: 30 });
      storage.session.get('token') && storage.session.set('token', headers.authorization);
    }
    const data = res.data
    if (data.code) {
      if (data.code !== 1 && data.code !== 206) {
        // 提示错误
        if (data.code === 9000) {
          if (data.data.popupwindow) {
            Message.error(data.msg);
          }
        }else if(data.code===3000){
          document.closetab();
        }
         else {
          Message.error(data.msg);
        }
        if (data.code === 3) {
          //去登录
          Cookies.get('token') && Cookies.remove('token');
          storage.session.get('token') && storage.session.remove('token');
          storage.session.get('tabs') && storage.session.remove('tabs');
          storage.session.get('currTab') && storage.session.remove('currTab');
          // Router.replace({
          //   path: '/login',
          // });
          location.href = '/#/newLogin';
        }
        return Promise.reject(data)
      }
    }
    return Promise.resolve(data)
  },
  error => {
    const { response } = error;
    const { status } = response;
    // if (status !== preStatus) {
    //   // 提示错误
    //   // 执行处理错误函数
    //   errHandle(status);
    // }
    // preStatus = status;
    errHandle(status);
    return Promise.reject(response);
  }
)
// @param: span 是否加载标识
const get = ({ url, params, headers, responseType }) => {
  return new Promise((resolve, reject) => {
    instance({
      url,
      method: 'GET',
      headers,
      params,
      responseType,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  })
}

const post = ({ url, data, headers, responseType }) => {
  return new Promise((resolve, reject) => {
    instance({
      url,
      method: 'POST',
      headers,
      data: qs.stringify(data),
      responseType,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  })
}

export {
  get,
  post,
  baseUrl,
};
