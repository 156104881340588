import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    include: [],
    pageSizes: [50, 100, 200, 300],
    isFlush:false,
    flushObj:{},
  },
  mutations: {
    addClude(state, payload) {
      const index = state.include.findIndex((item) => item === payload.route);
      if (index === -1) {
        state.include = [...state.include, payload.route];
      }
    },
    removeClude(state, payload) {
      state.include.splice(payload.index, 1);
    },
  },
  actions: {
  },
  modules: {
  },
});
