import { post } from '@/utils/request';

const myMenuReq = () => post({
  url: '/store/authority.user/myMenu',
});

// 门店列表
const getShopListReq = () => post({
  url: '/store/authority.User/shopList',
});

export {
  myMenuReq,
  getShopListReq,
};
